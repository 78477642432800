<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="wpItemData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching {{ $props.contentNameSingular }} data
      </h4>
      <div class="alert-body">
        No {{ $props.contentNameSingular }} found with this report id. Check
        <b-link
          class="alert-link"
          :to="{ name: $props.contentType }"
        >
          {{ $props.contentName }} list
        </b-link>
        for other {{ $props.contentName }}.
      </div>
    </b-alert>

    <template v-if="wpItemData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
        >
          <wp-items-view-info-card
            :wp-item-data="wpItemData"
            :content-type="$props.contentType"
          />
        </b-col>
        <b-col
          cols="12"
        >

          <wp-items-view-stats-card />
        </b-col>
      </b-row>

    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
import WpItemsViewInfoCard from './WpItemsViewInfoCard.vue'
import WpItemsViewStatsCard from './WpItemsViewStatsCard.vue'
import WpItemsViewPermissionsCard from './WpItemsViewPermissionsCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    WpItemsViewInfoCard,
    WpItemsViewStatsCard,
    WpItemsViewPermissionsCard,

  },
  props: {
    contentType: {
      type: String,
      required: true,
    },
    contentTypeSingular: {
      type: String,
      required: true,
    },
    contentName: {
      type: String,
      required: true,
    },
    contentNameSingular: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const wpItemData = ref(null)

    store.dispatch('app/getWpItem', {
      contentType: props.contentType,
      id: router.currentRoute.params.id,
    })
      .then(response => {
        wpItemData.value = response.data.results[0]
      })
      .catch(error => {
        if (error.response.status === 404) {
          wpItemData.value = undefined
        }
      })

    return {
      wpItemData,
    }
  },
}
</script>

<style>

</style>
