import { render, staticRenderFns } from "./WpItemsViewInfoCard.vue?vue&type=template&id=5fe8ddf4&"
import script from "./WpItemsViewInfoCard.vue?vue&type=script&lang=js&"
export * from "./WpItemsViewInfoCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports