<template>
  <b-card title="Stats">
    <h4>Statistics about this content item will appear here...</h4>
  </b-card>
</template>

<script>
import {
  BCard, BImg, BMedia,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BImg,
    BMedia,
  },
}
</script>

<style>

</style>
