<template>
  <b-card>
    <b-row>

      <!-- WpItem Info: Left col -->
      <b-col
        cols="21"
        xl="5"
        class="d-flex justify-content-between flex-column"
      >
        <!-- WpItem Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="wpItemData.icon_url"
            :text="avatarText(wpItemData.title)"
            size="100px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ wpItemData.title }}
              </h4>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                :to="{ name: $props.contentType+'-edit', params: { id: wpItemData.id } }"
                variant="primary"
              >
                Edit
              </b-button>
            </div>
          </div>
        </div>
        <table class="mt-3 w-100">
          <tr>
            <th class="pb-50 pr-1 text-nowrap align-top">
              <feather-icon
                  icon="CalendarIcon"
                  class="mr-75"
              />
              <span class="font-weight-bold">Last updated in WordPress</span>
            </th>
            <td class="pb-1">
              {{ formatDate(wpItemData.last_imported) }}
            </td>
          </tr>
          <tr>
            <th class="pb-50 pr-1 text-nowrap align-top">
              <feather-icon
                  icon="CalendarIcon"
                  class="mr-75"
              />
              <span class="font-weight-bold">Last updated in Extranet</span>
            </th>
            <td class="pb-1">
              {{ formatDate(wpItemData.updated_at) }}
            </td>
          </tr>
          <tr>
            <th class="pb-50 pr-1 text-nowrap align-top">
              <feather-icon
                  icon="EyeIcon"
                  class="mr-75"
              />
              <span class="font-weight-bold">Published status</span>
            </th>
            <td class="pb-1 text-capitalize">
              <b-badge :variant="(wpItemData.published) ? 'success' : 'light-secondary'">
                {{ (wpItemData.published) ? 'Published' : 'Draft' }}
              </b-badge>
            </td>
          </tr>
          <tr>
            <th class="pb-50 pr-1 text-nowrap align-top">
              <feather-icon
                  icon="StarIcon"
                  class="mr-75"
              />
              <span class="font-weight-bold">Featured</span>
            </th>
            <td class="pb-1 text-capitalize">
              <b-badge :variant="(wpItemData.featured) ? 'success' : 'light-secondary'">
                {{ (wpItemData.featured) ? 'Featured' : 'Not featured' }}
              </b-badge>
            </td>
          </tr>
          <tr v-if="$themeConfig.app.allowShowInFeed.includes(wpItemData.content_type)">
            <th class="pb-50 pr-1 text-nowrap align-top">
              <feather-icon
                  icon="FileTextIcon"
                  class="mr-75"
              />
              <span class="font-weight-bold">Show in news feed</span>
            </th>
            <td class="pb-1 text-capitalize">
              <b-badge :variant="(wpItemData.news) ? 'success' : 'light-secondary'">
                {{ (wpItemData.news) ? 'In news feed' : 'Not in feed' }}
              </b-badge>
            </td>
          </tr>
          <tr>
            <th class="pb-50 pr-1 text-nowrap align-top">
              <feather-icon
                  icon="CalendarIcon"
                  class="mr-75"
              />
              <span class="font-weight-bold">Published date</span>
            </th>
            <td class="pb-1">
              {{ formatDate(wpItemData.published_at) }}
            </td>
          </tr>
          <tr>
            <th class="pb-50 pr-1 text-nowrap align-top">
              <feather-icon
                  icon="UserIcon"
                  class="mr-75"
              />
              <span class="font-weight-bold">Owner</span>
            </th>
            <td v-if="wpItemData.owner" class="pb-1">
              <b-link
                  :to="{ name: 'users-view', params: { id: wpItemData.owner.id } }"
              >
                {{ wpItemData.owner.name }}
              </b-link>
            </td>
          </tr>
        </table>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="7"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-2 pr-1 text-nowrap align-top">
              <feather-icon
                  icon="LinkIcon"
                  class="mr-75"
              />
              <span class="font-weight-bold">Slug</span>
            </th>
            <td class="align-top">
              <b-link target="_blank" :href="$themeConfig.app.membersUrl +'/'+ $themeConfig.membersApp.routes[$props.contentType] +'/'+ wpItemData.slug">{{ wpItemData.slug }}</b-link>
            </td>
          </tr>
          <tr>
            <th class="pr-1 pb-2 text-nowrap align-top">
              <feather-icon
                  icon="FolderIcon"
                  class="mr-75"
              />
              <span class="font-weight-bold">Category</span>
            </th>
            <td v-if="wpItemData.category" class="align-top">
              <b-badge variant="warning">
                {{ wpItemData.category }}
              </b-badge>
            </td>
            <td v-else class="align-top">
              none
            </td>
          </tr>
          <tr>
            <th class="pr-1 pb-2 text-nowrap align-top">
              <feather-icon
                  icon="CalendarIcon"
                  class="mr-75"
              />
              <span class="font-weight-bold">Linked to event</span>
            </th>
            <td v-if="wpItemData.parent_event" class="align-top">
              <b-link :to="{ name: 'events-view', params: { id: wpItemData.parent_event.id } }">{{ wpItemData.parent_event.title }}</b-link>
            </td>
            <td v-else class="align-top">
              none
            </td>
          </tr>
          <tr>
            <th class="pb-2 pr-1 text-nowrap align-top">
              <feather-icon
                  icon="TagIcon"
                  class="mr-75"
              />
              <span class="font-weight-bold">Topics</span>
            </th>
            <td class="align-top">
              <ul
                  v-if="wpItemData.topics"
                  class="list-unstyled list-inline"
              >
                <li
                    v-for="topic in wpItemData.topics"
                    :key="topic.id"
                    class="mr-1"
                >
                  <b-badge variant="primary">
                    {{ topic.name }}
                  </b-badge>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <th class="pb-2 pr-1 text-nowrap align-top">
              <feather-icon
                  icon="BriefcaseIcon"
                  class="mr-75"
              />
              <span class="font-weight-bold">Organisations</span>
            </th>
            <td class="align-top">
              <ul
                  v-if="wpItemData.organisations"
                  class="list-unstyled list-inline"
              >
                <li
                    v-for="org in wpItemData.organisations"
                    :key="org.id"
                    class="mr-1"
                >
                  <b-badge variant="primary">
                    {{ org.name }}
                  </b-badge>
                </li>
              </ul>
            </td>
          </tr>
          <tr class="mt-2">
            <th class="pb-2 pr-1 text-nowrap align-top">
              <feather-icon
                icon="BarChartIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Total Views</span>
            </th>
            <td class="align-top">
              {{ wpItemData.all_analytics_count }}
            </td>
          </tr>
          <tr>
            <th class="pb-2 pr-1 text-nowrap align-top">
              <feather-icon
                icon="PieChartIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Views (Previous 28 days)</span>
            </th>
            <td class="align-top">
              {{ wpItemData.last_28_days_analytics_count }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BRow, BCol, BAvatar, BLink, BBadge,
} from 'bootstrap-vue'
import { $themeConfig } from '@/../themeConfig'
import { avatarText } from '@core/utils/filter'
import useWpItemsList from '../wp-items-list/useWpItemsList'
import formatDateMixin from '../../../@core/mixins/ui/date'

export default {
  components: {
    BBadge,
    BLink,
    BCard, BButton, BRow, BCol, BAvatar,
  },
  mixins: [formatDateMixin],
  props: {
    wpItemData: {
      type: Object,
      required: true,
    },
    contentType: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { resolveWpItemRoleVariant } = useWpItemsList()
    return {
      avatarText,
      resolveWpItemRoleVariant,
      $themeConfig,
    }
  },
}
</script>

<style>

</style>
